<script>
    import $ from "jquery";
    import { userSign, signList } from "@/api/discount";
    export default {
        name:'Checkin',
        components: {
            'mt-header': app.Mint.Header,
            'mt-button': app.Mint.Button,
            Header: () => import('components/base/Header'),
        },
        data() {
            return {
                list: [], //本月签到信息
                TodaySigned: false, //当天签到状态
                integral_num: 0, //签到获得的积分
                show: false,
                currentDay: 1,
                currentMonth: 1,
                currentYear: 1970,
                currentWeek: 1,
                days: [],
                curdays: [],
                sign1: [],
                sign2: [],
                Signrules: [],
                curMonth: this.DataTime.getDay(0)
                    .replace(/-/g, "")
                    .substring(0, 6),
                signCount: "",
                signContinuteNum: "",

                header:{
                    mainTitle: getWord('check_in_lucky_draw3'),
                },
            };
        },
        computed: {
            one() {
                return parseInt(this.signCount % 10);
            },
            ten() {
                return parseInt((this.signCount % 100) / 10);
            },
            hundred() {
                return parseInt(this.signCount / 100);
            }
        },
        created: function() {
            this.initData(null);
        },
        methods: {
            //转换时间
            getLocalTime(ns) {
                return new Date(parseInt(ns) * 1000).getDate();
            },
            //签到信息
            getList() {
                var that=this;
                signList()
                    .then(result => {
                        if (result.data.code == "SUCCESS") {
                            that.list = result.data.result;
                            for (let i = 0; i < that.list.Sign.length; i++) {
                                that.list.Sign[i] = that.getLocalTime(that.list.Sign[i]);
                            }
                            that.curdays = that.list.Sign;
                            let atDay = Number(that.DataTime.getDay(0).substring(8));
                            if (that.curdays.indexOf(atDay) > -1) {
                                that.TodaySigned = true;
                            }
                        } else if (result.data.code == "FAILUE") {
                            app.Mint.Toast(result.data.msg);
                        } else {
                            app.Mint.Toast(result.data.msg);
                        }
                    })
            },
            //点击签到
            setSignUserIo() {
                userSign()
                    .then(result => {
                        if (result.data.code == "SUCCESS") {
                            this.getList();
                            this.integral_num = result.data.result.integral_num;
                            this.show = true;
                        } else if (result.data.code == "FAILUE") {
                            app.Mint.Toast(result.data.msg);
                        } else {
                            app.Mint.Toast(result.data.msg);
                        }
                    })
            },

            //关闭签到弹窗
            async close() {
                this.show = false;
                this.TodaySigned = true;
                this.curMonth = this.DataTime.getDay(0)
                    .replace(/-/g, "")
                    .substring(0, 6);
            },
            initData: function(cur,_date) {
                var date = new Date();
                var index = 0;
                if (cur) {
                    date = new Date(cur);
                } else {
                    var now = new Date();
                    var d = new Date(this.formatDate(now.getFullYear(), now.getMonth() + 1, 1));
                    date = new Date(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
                }
                this.currentDay = date.getDate();
                this.currentYear = date.getFullYear();
                if (_date) {
                    this.currentMonth = _date.month;
                }else {
                    this.currentMonth = date.getMonth() + 1;
                }
                this.currentWeek = date.getDay();
                if (this.currentWeek == 0) {
                    this.currentWeek = 7;
                }
                var str = this.formatDate(
                    this.currentYear,
                    this.currentMonth,
                    this.currentDay
                );
                this.days.length = 0;
                // 今天是周日，放在第一行第7个位置，前面6个
                //初始化本周
                for (var i = this.currentWeek - 1; i >= 0; i--) {
                    var d = new Date(str);
                    d.setDate(d.getDate() - i);
                    var dayobject = {};
                    dayobject.day = d;
                    var now = new Date();
                    if (
                        d.getDate() === now.getDate() &&
                        d.getMonth() === now.getMonth() &&
                        d.getFullYear() === now.getFullYear()
                    ) {
                        dayobject.index = index++; //从今天开始显示供预定的数量
                    } else if (index != 0 && index < 3) dayobject.index = index++; //从今天开始3天内显示供预定的数量
                    this.days.push(dayobject); //将日期放入data 中的days数组 供页面渲染使用
                }
                //其他周
                for (var i = 1; i <= 42 - this.currentWeek; i++) {
                    var d = new Date(str);
                    d.setDate(d.getDate() + i);
                    var dayobject = {};
                    dayobject.day = d;
                    var now = new Date();
                    if (
                        d.getDate() === now.getDate() &&
                        d.getMonth() === now.getMonth() &&
                        d.getFullYear() === now.getFullYear()
                    ) {
                        dayobject.index = index++;
                    } else if (index != 0 && index < 3) dayobject.index = index++;
                    this.days.push(dayobject);
                }
            },
            pickPre: function(year, month) {
                // setDate(0); 上月最后一天
                // setDate(-1); 上月倒数第二天
                // setDate(dx) 参数dx为 上月最后一天的前后dx天
                var d = new Date(this.formatDate(year, month, 1));
                d.setDate(0);
                this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
                this.curMonth = this.formatDate(d.getFullYear(), d.getMonth() + 1, 1)
                    .replace(/-/g, "")
                    .substring(0, 6);
            },
            pickNext: function(year, month) {
                var d = new Date(this.formatDate(year, month, 1));
                d.setDate(42);
                this.initData(this.formatDate(d.getFullYear(), d.getMonth() + 1, 1));
                this.curMonth = this.formatDate(d.getFullYear(), d.getMonth() + 1, 1)
                    .replace(/-/g, "")
                    .substring(0, 6);
            },
            pickYear: function(year, month) {
                // alert(year + "," + month);
            },
            // 返回 类似 2018-08-05 格式的字符串
            formatDate: function(year, month, day) {
                var y = year;
                var m = month;
                if (m < 10) m = "0" + m;
                var d = day;
                if (d < 10) d = "0" + d;
                return y + "-" + m + "-" + d;
            },
            previousMonth(){
                this.pickPre(this.currentYear,this.currentMonth);
            },
            nextMonth(){
                this.pickNext(this.currentYear,this.currentMonth);
            }
        },
        mounted() {
            this.getList();
        }
    };
</script>
<template>
    <div id="sign" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1' || _TEMPLATE==='template-2'">
            <div class="headerFix">
                <mt-header fixed :title="getWord('daily_check_in')">
                    <router-link to="/home" slot="left">
                        <mt-button icon="back"></mt-button>
                    </router-link>
                </mt-header>
            </div>
            <div class="scroll">
                <p style="font-size:0.5rem;font-weight:bold;text-align:center;top:1.65rem;position:relative;">{{list.Count}}</p>
                <p style="position: relative;top:1.4rem;left:55%;width:0.5rem;">{{ getWord('day') }}</p>
                <img src="@@/assets/images2/signIn/signInIon.png" style="margin:0.1rem 0 0 3rem" />
                <p style="color:#FFF;font-size:0.36rem;text-align:center;">{{ getWord('check_in_this_month') }}</p>
                <div id="calendar">
                    <div class="month">
                        <ul style="list-style:none;font-size:0.26rem;">
                            <li>
                                <img src="@@/assets/images2/signIn/dataion.png" style="margin-right:0.1rem;float:left;" /><span>{{ currentYear }} {{ getWord('year') }}</span><span> {{ currentMonth }} {{ getWord('month') }}</span>
                            </li>
                            <li>{{ getWord('remaining') }}<span style="padding:0 0.1rem;color:#FF9627;">{{list.Integral}}</span>{{ getWord('points_of_app') }}</li>
                        </ul>
                    </div>
                    <ul class="weekdays">
                        <li>{{ getWord('monday') }}</li>
                        <li>{{ getWord('tuesday') }}</li>
                        <li>{{ getWord('wednesday') }}</li>
                        <li>{{ getWord('thursday') }}</li>
                        <li>{{ getWord('friday') }}</li>
                        <li>{{ getWord('saturday') }}</li>
                        <li>{{ getWord('sunday') }}</li>
                    </ul>
                    <ul class="days">
                        <li v-for="(dayobject,i) in days" :key="i">
                            <span v-if="dayobject.day.getMonth()+1 != currentMonth" class="other-month">{{ dayobject.day.getDate() }}</span>
                            <span v-else>
                                <span v-if="curdays.indexOf(dayobject.day.getDate())!=-1" class="active">
                                    <span v-if="dayobject.day.getDate()>=10">{{ dayobject.day.getDate() }}</span>
                                    <span v-else>0{{ dayobject.day.getDate() }}</span>
                                </span>
                                <span v-else>
                                    <span v-if="dayobject.day.getDate()>=10">{{ dayobject.day.getDate() }}</span>
                                    <span v-else>0{{ dayobject.day.getDate() }}</span>
                                </span>
                            </span>
                        </li>
                    </ul>
                </div>
                <p v-if="TodaySigned == false" class="sigin-btn" @click="setSignUserIo">{{ getWord('check_in') }}</p>
                <p v-else class="sigin-btn" style="background:#999999;">{{ getWord('checked_in') }}</p>
                <p style="width:100%;height:1rem;"></p>
            </div>
            
        </template>

        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />

            <main>

                <div class="ceiling">
                    <span class="decoration"></span>
                </div>
            
                <div id="calendar">

                    <div class="info">
                        <span>{{ getWord('check_in_this_month') }}<b>{{list.Count}}</b>{{ getWord('day') }}</span>
                        <span>{{ getWord('remaining') }}:<b>{{list.Integral}}</b>{{ getWord('points_of_app') }}</span>
                    </div>

                    <!-- <div class="month-selector">
                        <i class="iconfont icon-back previous-month" @click="previousMonth"></i>
                        <span>{{ currentYear }}年{{ currentMonth }}月</span>                        
                        <i class="iconfont icon-back next-month" @click="nextMonth"></i>                        
                    </div> -->

                    <ul class="weekdays">
                        <li>{{ getWord('monday') }}</li>
                        <li>{{ getWord('tuesday') }}</li>
                        <li>{{ getWord('wednesday') }}</li>
                        <li>{{ getWord('thursday') }}</li>
                        <li>{{ getWord('friday') }}</li>
                        <li>{{ getWord('saturday') }}</li>
                        <li>{{ getWord('sunday') }}</li>
                    </ul>
                    <ul class="days">
                        <li v-for="(dayobject,i) in days" :key="i">
                            <span v-if="dayobject.day.getMonth()+1 != currentMonth" class="other-month">{{ dayobject.day.getDate() }}</span>
                            <span v-else>
                                <span v-if="curdays.indexOf(dayobject.day.getDate())!=-1" class="active">
                                    <span v-if="dayobject.day.getDate()>=10">{{ dayobject.day.getDate() }}</span>
                                    <span v-else>0{{ dayobject.day.getDate() }}</span>
                                </span>
                                <span v-else>
                                    <span v-if="dayobject.day.getDate()>=10">{{ dayobject.day.getDate() }}</span>
                                    <span v-else>0{{ dayobject.day.getDate() }}</span>
                                </span>
                            </span>
                        </li>
                    </ul>

                    <button :class="{disabled:TodaySigned}">
                        <span v-if="TodaySigned===false" @click="setSignUserIo">{{ getWord('check_in2') }}</span>
                        <span v-else>{{ getWord('checked_in') }}</span>
                    </button>
                </div>

            </main>
            
        </template>

        <transition name="fade">
            <div class="checkin-success" v-if="show">

                <div class="inner">
                    <span class="position-1">+{{integral_num}}</span>

                    <span class="position-2">{{ getWord('checked_in_success') }}{{list.Count}}{{ getWord('day') }}</span>

                    <span class="position-3">{{ getWord('checked_in_success2') }}! {{ getWord('points_of_app') }}+{{integral_num}}</span>

                    <span class="close" @click="show=false"><i class="iconfont icon-23"></i></span>
                </div>
            </div>
        </transition>
    </div>
</template>
<style lang="scss" scoped>
#sign {
    margin: 0.8rem 0 0 0;
    padding: 0;
    width: 100%;
    height: auto;
    background-image: url('/static/assets/images2/signIn/signInBack.png');
    background-color: #e06b20;
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;
    background-position: center;
    padding-bottom: 0.5rem;
    overflow: auto;

    &.template-3 {
        margin:0;
        height: 100%;
        background-image:url('/static/assets/images/personal/bg/checkin.png');
        background-position-y: -.75rem;
        background-repeat: no-repeat;
        background-color: #F49663;

        main {
            margin: 3rem .25rem 0 .25rem;

            .ceiling {
                background-color: #FFCE9B;
                border-radius: .5rem;
                padding: .15rem;

                .decoration {
                    background-color:#F28818;
                    height: .2rem;
                    display: block;
                }
            }

            #calendar {
                background-image: url('/static/assets/template-3/images/home/signin/bg/footer.png');
                background-color: #ffffff;
                margin: 0 .15rem;
                margin-top: -.025rem;
                padding-bottom: .33rem;
                background-size: 1rem;
                background-position: 101% 101%;

                .info {
                    font-size: .28rem;
                    display: flex;
                    justify-content: space-between;
                    background-color: #FFE5D6;
                    background: linear-gradient(180deg, rgba(254,198,137,1) 0%, rgba(255, 229, 214, 1) 50%, rgba(254,228,200,1) 100%);
                    padding: .15rem .5rem;

                    b {
                        font-weight: normal;
                        color: #F18815;
                    }

                    + .weekdays {
                        margin-top: .25rem;
                    }
                }

                .month-selector {
                    font-size: .28rem;
                    color: #F18815;
                    text-align: center;
                    padding: .15rem;

                    > * {
                        vertical-align: middle;
                        display: inline-block;
                    }

                    span {
                        padding: 0 .25rem;
                    }

                    .previous-month {

                    }

                    .next-month {
                        transform: rotateZ(180deg);
                    }
                }

                .weekdays {
                    border-bottom: 0;
                    color: #F18815;
                }

                .days {

                    li {

                        .active {
                            background-color: #F28818;
                        }
                    }
                }

                > button {
                    margin-top: .25rem;
                    font-size: .3rem;
                    border: 0;
                    margin: 0 auto;
                    display: block;
                    min-width: 1rem;
                    padding: .1rem .66rem;
                    background-color: #F28818;
                    color: #ffffff;
                    border-radius: .3rem;
                    outline: none;

                    &.disabled {
                        opacity: .5;
                    }
                }
            }
        }

        .checkin-success {
            position: fixed;
            left:0;
            top:0;
            width: 100%;
            height: 100%;
            z-index: 11;
            background-color:rgba(0,0,0,.5);

            .inner {
                background-image:url('/static/assets/images/personal/bg/checkin-success.png');
                width: 5.5rem;
                height: 5.5rem;
                background-size: cover;
                position:absolute;
                top: 40%;
                left: 50%;
                transform: translate(-50%, -40%);

                span {
                    position:absolute;

                    &.position-1 {
                        color: #F9F7D0;
                        font-size: .55rem;
                        top: 0.95rem;
                        right: 1.7rem;
                        letter-spacing: -.025rem;
                    }

                    &.position-2 {
                        color: #ffffff;
                        font-size: .3rem;
                        top: 2.8rem;
                        left: 0;
                        width: 100%;
                        text-align: center;
                    }

                    &.position-3 {
                        color: #333333;
                        font-size: .36rem;
                        top: 4.6rem;
                        left: 0;
                        width: 100%;
                        text-align: center;
                    }

                    &.close {
                        width: .55rem;
                        height: .55rem;
                        line-height: .55rem;
                        background-color: #ffffff;
                        border-radius: 100%;
                        text-align: center;
                        top: -.1rem;
                        right: .1rem;

                        .iconfont {
                            color: #FF9726;
                            font-size: 0.33rem;
                            vertical-align: middle;
                        }
                    }
                }                
            }
        }
    }

    .scroll {
        .sigin-btn {
            font-size: 0.32rem;
            background: #4faef9;
            color: white;
            margin: 0 auto;
            width: 94.5%;
            height: 1rem;
            line-height: 1rem;
            margin-top: -1.43rem;
            text-align: center;
            border-bottom-left-radius: 0.2rem;
            border-bottom-right-radius: 0.2rem;
            font-weight: bold;
        }

        .sigined-btn {
            background: #dddddd;
            box-shadow: #dddddd 0 0px 0px;
            color: #999999;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: .5rem;
                margin-right: .2rem;
            }
        }

        &>div {
            margin: 0.2rem 0.2rem 0 0.2rem;
            border-radius: 0.2rem;
            padding: 0.2rem 0.2rem 3.5rem 0.2rem;
        }

        &>div:last-child {
            background: #f2f2f2;

            p:first-child {
                font-size: 0.26rem;
                margin-bottom: 0.15rem;
            }

            p {
                color: #666666;
                font-size: 0.24rem;
                margin-bottom: 0.1rem;
            }
        }

        .signbg {
            background: #4faef9;
            position: relative;
            margin: 0;
            padding-bottom: 0.7rem;
            margin-bottom: 1.5rem;
            border-radius: 0;

            .signday {
                font-size: 0.32rem;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #fff;

                span {
                    font-size: 0.4rem;
                    text-align: center;
                    color: #4faef9;
                    display: inline-block;
                    width: 0.5rem;
                    height: 0.6rem;
                    border-radius: 0.06rem;
                    line-height: 0.6rem;
                    margin-left: 0.2rem;
                }

                span:last-child {
                    margin-right: 0.2rem;
                }
            }

            .continuity {
                font-size: 0.24rem;
                text-align: center;
                margin: 0.3rem 0;
                color: #FFF;
            }

            .activity {
                background: #fff;
                position: absolute;
                left: 0.3rem;
                right: 0.3rem;
                border-radius: 0.2rem;
                display: flex;
                top: 1.7rem;
                padding: 0.2rem 0;
                justify-content: space-around;
                color: #4faef9;

                div {
                    border-radius: 0.1rem;
                    height: 1.5rem;
                    width: 2rem;
                    text-align: center;
                    position: relative;
                    width: 50%;

                    p {
                        height: 0.4rem;
                        line-height: 0.4rem;
                        font-size: 0.24rem;
                    }

                    p:first-child {
                        font-size: 0.4rem;
                        margin: 0.15rem 0;
                        border-top-left-radius: 0.1rem;
                        border-top-right-radius: 0.1rem;
                    }

                    p:nth-child(2) {
                        height: 0.3rem;
                        line-height: 0.3rem;
                        margin-bottom: 0.1rem;
                    }

                    span {
                        display: inline-block;
                        padding: 0 0.3rem;
                        line-height: 0.4rem;
                        background: #4faef9;
                        color: white;
                        border-radius: 0.2rem;
                    }

                    span.signed {
                        background: #f2f2f2;
                        color: #999999;
                        border-radius: 0.2rem;
                        width: auto;
                        padding: 0 0.3rem;
                    }
                }

                div:first-child::before {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0.2rem;
                    bottom: 0.2rem;
                    width: 1px;
                    background: #dddddd;
                }
            }
        }
    }

    #calendar {

        .month {

            ul {
                justify-content: space-between;
                margin-top: -.1rem;

                li {
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}

.signpop {
    .content-bg {
        position: fixed;
        bottom: 0;
        top: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
    }

    .content {
        position: absolute;
        width: 4rem;
        height: 5rem;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        margin-left: -2.065rem;
        margin-top: -2rem;
        text-align: center;

        img {
            max-width: 100%;
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
        }

        p {
            position: relative;
            top: 65%;
            color: #4faef9;
            font-size: 0.36rem;
        }

        p:last-child {
            font-size: 0.44rem;
            color: #fa5477;

            span {
                font-size: 0.28rem;
            }
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.month {
    width: 100%;
}

.month ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.2rem;
}

.year-month {
    font-size: 0.28rem;
    margin: 0 0.3rem;
}

.arrow {
    font-size: 0.3rem;
    color: #999999;
}

.weekdays {
    margin: 0;
    padding: 0.1rem 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    // border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;
    font-size: 0.26rem;
    color: #DC2103;
}

.weekdays li {
    display: inline-block;
    width: 13.6%;
    text-align: center;
}

.days {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: 0.26rem;
    color: #999999;

    li {

        span {
            width: .6rem;
            height: .6rem;
            line-height: .6rem;            
            display: inline-block;
            text-align: center;
            border-radius: 50%;
        }
    }
}

.days li {
    list-style-type: none;
    display: inline-block;
    width: 14.2%;
    text-align: center;
    padding-bottom: 0.1rem;
    padding-top: 0.1rem;
}

.days li .active {
    background: #4796FC;
    color: #fff;
}

.days li .other-month {
    color: gainsboro;
    display: none;
}

#calendar {
    background-image: url('/static/assets/images2/signIn/signInDate.png');
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: 100%;
}
</style>